import samokat from '../assets/img/offers/samokat.png';
import hh from '../assets/img/offers/hh.png';
import ozon from '../assets/img/offers/ozon.png';
import sberbank from '../assets/img/offers/sberbank.png';
import qlean from '../assets/img/offers/qlean.png';
import yandex_taxi from '../assets/img/offers/yandex_taxi.png';
import tinkoff from '../assets/img/offers/tinkoff.png';
import voxys from '../assets/img/offers/voxys.png';


export type OfferType = {
    logo: string,
    id: string,
    title: string,
    CR: string,
    AR: string,
    EPL: string,
    EPC: string,
    statistic: string,
    hold: string,
    payment: string,
    bonus: string
}

export const SamokatOffer: OfferType = {
    logo: samokat,
    id: "ID 10144",
    title: "Caмокат HR",
    CR: "42.2%",
    AR: "11.5%",
    EPL: "276.6₽",
    EPC: "56.6₽",
    statistic: "Два раза в месяц",
    hold: "7 дней",
    payment: "1 920₽",
    bonus: "979"
}


export const HhOffer: OfferType = {
    logo: hh,
    id: "ID 10156",
    title: "HeadHunter",
    CR: "4.8%",
    AR: "22.2%",
    EPL: "746.6₽",
    EPC: "32.7₽",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "3 360₽",
    bonus: "1 713"
}

export const OzonOffer: OfferType = {
    logo: ozon,
    id: "ID 10045",
    title: "OZON HR Delivery",
    CR: "42.7%",
    AR: "0.7%",
    EPL: "13.46₽",
    EPC: "5.55₽",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "1 840₽",
    bonus: "938"
}

export const SberbankOffer: OfferType = {
    logo: sberbank,
    id: "ID 10618",
    title: "СБЕР HR",
    CR: "4.2%",
    AR: "00.0%",
    EPL: "00.0₽",
    EPC: "00.0₽",
    statistic: "Два раза в месяц",
    hold: "7 дней",
    payment: "3 850₽",
    bonus: "1 855"
}

export const QleanOffer: OfferType = {
    logo: qlean,
    id: "ID 9598",
    title: "Qlean HR",
    CR: "14.6%",
    AR: "00.0%",
    EPL: "00.0₽",
    EPC: "00.0₽",
    statistic: "Два раза в месяц",
    hold: "7 дней",
    payment: "2 400₽",
    bonus: "1 224"
}

export const YandexTaxiOffer: OfferType = {
    logo: yandex_taxi,
    id: "ID 9581",
    title: "Яндекс.Такси HR",
    CR: "26.9%",
    AR: "1.5%",
    EPL: "8.14₽",
    EPC: "30.29₽",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "2 080₽",
    bonus: "1 060"
}

export const TinkoffOffer: OfferType = {
    logo: tinkoff,
    id: "ID 982",
    title: "Тинькофф",
    CR: "13.5%",
    AR: "6.7%",
    EPL: "91.41₽",
    EPC: "10.25₽",
    statistic: "Раз в неделю",
    hold: "3 дня",
    payment: "1 360₽",
    bonus: "0"
}

export const VoxysOffer: OfferType = {
    logo: voxys,
    id: "ID 10083",
    title: "Voxys HR",
    CR: "40.7%",
    AR: "0.91%",
    EPL: "37.31₽",
    EPC: "10.94₽",
    statistic: "Раз в неделю",
    hold: "7 дней",
    payment: "4 080₽",
    bonus: "0"
}