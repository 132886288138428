
import React from "react";
//import facebook from '../assets/icons/facebook.png';
import youtube from '../assets/icons/youtube.png';
import vk from '../assets/icons/vk.png';
import telegram from '../assets/icons/telegram.png';
//import instagram from '../assets/icons/instagram.png';
import { Footer } from "antd/es/layout/layout";

const FooterSection: React.FC = () => {
    return (
        <Footer style={{backgroundColor: '#FFFFFF', position: 'relative', zIndex: 1, border: '1px solid #D9DDE6'}}>
            <div className="footer_wrapper">
                <div>Copyright © LEADS.SU 2010-2023. <br/>
                    Все права защищены.
                </div>
                <div className="footer_links">
                    <a href="https://www.youtube.com/@LEADS_SU" target='_blank' rel="noreferrer" className="footer_link">
                        <img src={youtube} alt='icon'/>
                    </a>
                    <a href="https://vk.com/leads_su" target='_blank' rel="noreferrer" className="footer_link">
                        <img src={vk} alt='icon'/>
                    </a>
                    <a href="https://t.me/leads_hr" target='_blank' rel="noreferrer" className="footer_link">
                        <img src={telegram} alt='icon'/>
                    </a>
                </div>
                <div>
                    +7 (800) 500-2423 <br/>
                    <span style={{color: "#318EE3"}}>support@leads.su</span>
                </div>
            </div>
        </Footer>
    )
}

export default FooterSection;