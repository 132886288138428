import { Button } from "antd";
import React from "react";
import CardAdvantage from "./CardAdvantage";
import { card1, card2, card3 } from "./data-card-advantage";
import union from '../assets/img/union-footer.png';


const SectionAdvantage: React.FC = () => {
    return (
        <div id="advertiser" className="section_advantage">
            <h3>Преимущества работы для рекламодателя</h3>
            <div className="advantage_wrapper">
               <CardAdvantage {...card1}/>
               <CardAdvantage {...card2}/>
               <CardAdvantage {...card3}/>
               <div className="advantage_text">
                    <h5>Регистрируйтесь и получите поток кандидатов уже завтра</h5>
                    <a href="https://leads.su/adv" target='_blank' rel="noreferrer" className="button_link">
                        <Button type="primary" danger>Зарегистрироваться</Button>
                    </a>
               </div>
               <hr className="arrow-1"/>
               <hr className="arrow-2"/>
               <hr className="arrow-3"/>
            </div>
            <div className="section_advantage_union">
                <img src={union} alt='union'/>
            </div>

        </div>
    )
}

export default SectionAdvantage;