import { Row, Col, Card } from 'antd';
import React from 'react';
import './offer.css';
import bolt from '../assets/img/offers/bolt.png';
import { OfferType } from './data-offers';

const OfferCard: React.FC<OfferType> = (props) => {
    return (
        <Card className='offer_wrapper'>
            <Row className='offer_header' style={{display: 'flex', alignItems: "center"}}>
                <Col span={12} style={{display: 'flex'}}>
                    <img src={props.logo} alt='logo'/>
                </Col>
                <Col span={12} style={{
                    textAlign: 'right',
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#8C90A2",   
                }}>
                    <p style={{ margin: 0 }}>{props.id}</p>
                </Col>
            </Row>
            <Row className='offer_title'>
                <Col span={24}>
                    <h5>{props.title}</h5>
                    <p>HR офферы</p>
                </Col>
            </Row>
            <Row className='offer_percent'>
                <Col >
                    <p>CR</p>
                    <p>{props.CR}</p>
                </Col>
                <Col >
                    <p>AR</p>
                    <p>{props.AR}</p>
                </Col>
                <Col >
                    <p>EPL</p>
                    <p>{props.EPL}</p>
                </Col>
                <Col >
                    <p>EPC</p>
                    <p>{props.EPC}</p>
                </Col>
            </Row>
            <Row className='offer_description'>
                <Row justify="space-between" style={{height: '24px'}}>
                    <Col flex='95px' className="offer_text_left">Статистика</Col>
                    <Col style={{ width: 'calc(100% - 95px)'}} className="offer_text_right">{props.statistic}</Col>
                </Row>
                <Row justify="space-between" style={{height: '24px'}}>
                    <Col span={12} className="offer_text_left">Тип оплаты</Col>
                    <Col span={12} className="offer_text_right">CPS</Col>
                </Row>
                <Row justify="space-between" style={{height: '24px'}}>
                    <Col span={12} className="offer_text_left">Hold</Col>
                    <Col span={12} className="offer_text_right">{props.hold}</Col>
                </Row>
                <Row justify="space-between" style={{height: '24px'}}>
                    <Col span={12} className="offer_text_left">Гео</Col>
                    <Col span={12} className="offer_text_right">Нет</Col>
                </Row>
            </Row>
            <hr/>
            <Row className='offer_payment'>
                <Row justify="space-between" style={{height: '28px'}}>
                    <Col span={12} className="offer_text_left">Оплата:</Col>
                    <Col span={12} className="offer_payment_right">{props.payment}</Col>
                </Row>
                <Row justify="space-between" style={{height: '24px'}}>
                    <Col span={12} className="offer_text_left">Бонус:</Col>
                    <Col span={12} className="offer_payment_right_2">{props.bonus} <img src={bolt} alt='bolt'/></Col>
                </Row>
            </Row>
        </Card>
        
    )
}

export default OfferCard;