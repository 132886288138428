import { Card, Row, Col } from 'antd';
import React from 'react';
import { CardAdvantageType } from './data-card-advantage';

const CardAdvantage: React.FC<CardAdvantageType> = (props) => {
    return (
        <Card className='card_advantage_wrapper' id={props.id}>
            <Row className='card_advantage_header'>
                <Col><img src={props.icon} alt='icon' className='card_advantage_icon'/></Col>
                <Col dangerouslySetInnerHTML={{__html: props.title}} className="card_advantage_title"></Col>
            </Row>
            <Row className='card_advantage_text'>
                <Col>
                    {props.text}
                </Col>
            </Row>
        </Card>
    )
}

export default CardAdvantage