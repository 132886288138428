import { Button, Carousel } from "antd";
import React from "react";
import { HhOffer, OzonOffer, QleanOffer, SamokatOffer, SberbankOffer, TinkoffOffer, VoxysOffer, YandexTaxiOffer } from "./data-offers";
import OfferCard from "./Offer";
import './section.css';


const SectionOffers: React.FC = () => {

    const onChange = (currentSlide: number) => {
        console.log(currentSlide);
      };

    return (
        <div className="section_offers">
            <h3>Наши офферы</h3>
            <div className="offers_container">
                <OfferCard {...SamokatOffer}/>
                <OfferCard {...HhOffer}/>
                <OfferCard {...OzonOffer}/>
                <OfferCard {...SberbankOffer}/>

                <OfferCard {...QleanOffer}/>
                <OfferCard {...YandexTaxiOffer}/>
                <OfferCard {...TinkoffOffer}/>
                <OfferCard {...VoxysOffer}/>
            </div>
            <div className="carousel_offers">
                <Carousel afterChange={onChange}>
                    <OfferCard {...SamokatOffer}/>
                    <OfferCard {...HhOffer}/>
                    <OfferCard {...OzonOffer}/>
                    <OfferCard {...SberbankOffer}/>

                    <OfferCard {...QleanOffer}/>
                    <OfferCard {...YandexTaxiOffer}/>
                    <OfferCard {...TinkoffOffer}/>
                    <OfferCard {...VoxysOffer}/>
                </Carousel>
            </div>
            <h5>Зарегистрируйся и получи доступ ко всем офферам Leads</h5>
            <a href="https://webmaster.leads.su/register" target='_blank' rel="noreferrer" className="button_link">
                <Button type="primary" danger>Зарегистрироваться</Button>
            </a>
            
        </div>
    )
}

export default SectionOffers;