import { ConfigProvider } from 'antd';
import Layout from 'antd/es/layout';
import React from 'react';
import './App.css';
import Footer from './components/Footer';
import HeaderSection from './components/Header';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import SectionAdvantage from './components/SectionAdvantage';
import SectionHead from './components/SectionHead';
import SectionOffers from './components/SectionOffers';
import Partners from './components/SectionPartners';

function App() {
  return (

    <ConfigProvider
      theme={{
        token: {   
            fontFamily: 'Montserrat, Ubuntu, san-serif'
        }
      }}>
        <Layout className='app_layout'>
            <HeaderSection/>
            <SectionHead/>
            <Section1/>
            <Section2/>
            <Section3/>
            <Partners/>
            <SectionOffers/>
            <SectionAdvantage/>
            <Footer/>
        </Layout>
      </ConfigProvider>
  );
}

export default App;
