import React from "react";
import samokat from '../assets/img/samokat.png';
import tinkoff from '../assets/img/tinkoff.png';
import sberbank from '../assets/img/sberbank.png';
import yaeda from '../assets/img/yaeda.png';
import yago from '../assets/img/yago.png';
import ozon from '../assets/img/ozon.png';
import kfc from '../assets/img/kfc.png';
import voxys from '../assets/img/voxys.png';
import sbermarket from '../assets/img/sbermarket.png';
import delivery from '../assets/img/delivery.png';
import burgerking from '../assets/img/burger.png';
import citymobil from '../assets/img/citymobil.png';
import hh from '../assets/img/hh.png';

const Partners: React.FC = () => {
    return (
        <div className="section_partners">
            <h3>Наши партнеры</h3>
            <div className="section_partners__partners">
                <div className="partners_img">
                    <img src={samokat} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={tinkoff} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={sberbank} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={yaeda} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={yago} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={ozon} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={kfc} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={voxys} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={sbermarket} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={delivery} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={burgerking} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={citymobil} alt="icon_partner"/>
                </div>
                <div className="partners_img">
                    <img src={hh} alt="icon_partner"/>
                </div>
            </div>
        </div>
    )
}

export default Partners;