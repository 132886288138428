import icon1 from '../assets/icons/advantage-1.png';
import icon2 from '../assets/icons/advantage-2.png';
import icon3 from '../assets/icons/advantage-3.png';

export type CardAdvantageType = {
    icon: string,
    title: string,
    text: string,
    id: string
}

export const card1: CardAdvantageType = {
    id: 'card1',
    icon: icon1,
    title: "Уникальный <br/>подход",
    text: "Мы настроим работу с вами так, чтобы вы получали кандидатов наиболее удобным для вас способом"
}

export const card2: CardAdvantageType = {
    id: 'card2',
    icon: icon2,
    title: "Стабильный и уникальный трафик",
    text: "Наши вебмастера обеспечат Вас большим и стабильным потоком релевантных кандидатов"
}

export const card3: CardAdvantageType = {
    id: 'card3',
    icon: icon3,
    title: "Экономьте <br/> на подборе",
    text: "Платите только за совершенные целевые действия"
}