import React from "react";
import rocket from '../assets/img/rocket.png';
import union from '../assets/img/union-rocket.png';

const Section3: React.FC = () => {
    return (
        <div className="section3_wrapper">
            <div className="section3_text">
                <h3 style={{
                    color: '#303446',
                    fontSize: '38px',
                    lineHeight: '56px',
                    fontWeight: 600,
                    margin: 0
                }}>
                    Начало работы <br/>Вебмастера</h3>
                <h5 style={{
                    color: '#303446',
                    fontSize: '24px',
                    lineHeight: '32px',
                    fontWeight: 600,
                    margin: 0
                }}>
                    Начать зарабатывать с Leads<br/> очень просто</h5>
                <div className="section3_table">
                    <p> <span>1.</span> Зарегистрируйся в системе</p>
                    <p> <span>2.</span> Подключи оффер</p>
                    <p> <span>3.</span> Приведи соискателей к работодателю</p>
                    <p> <span>4.</span> Забери денежное вознаграждение</p>
                </div>
                
            </div>
            <div className="section3_img">
                <img src={rocket} alt='img_rocket' className="section3_img_rocket"/>
                <img src={union} alt='img_union' className="section3_img_union"/>
            </div>
        </div>
    )
}

export default Section3;