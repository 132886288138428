import React from "react";
import './section.css';
import people from '../assets/img/people.png';

const Section1: React.FC = () => {
    return (
        <div className="section1_wrapper">
            <div style={{marginTop: '133px'}}>
                <p style={{
                    color: '#303446',
                    fontSize: '38px',
                    lineHeight: '56px',
                    fontWeight: 600,
                    margin: 0,
                    width: '499px'
                }}>
                    С помощью нашей <br/> команды было <br/> трудоустроено более <br/>
                    <span style={{color: '#ED5252'}}> 100 000</span> человек
                </p>
            </div>

            <img src={people} alt='img_people'/>
        </div>
    )
}

export default Section1;